import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
  Spin,
  Table,
  Empty,
  Flex,
} from "antd";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CustomerMenu from "./CustomerMenu";
import SalesMenu from "../Sales/SalesMenu";
import axios from "axios";
import Config from "../../Config";
import SubMenuToggle from "../Common/SubMenuToggle";
import dayjs from "dayjs";
import CustomersDropdown from "../Common/CustomersDropdown";
import { PrinterOutlined, UploadOutlined } from "@ant-design/icons";
import PrintTable from "../Shared/PrintTable";
import Logo from "../../assets/images/logo/dark-h.svg";
import ExcelJS from "exceljs";
import ReportsMenu from "../Reports/ReportsMenu";
import CustomerDropdown from "../Shared/CustomerDropdown";

const CustomerReceiptDetails = () => {
  const navigate = useNavigate();
  const AccessKey = localStorage.getItem("AccessKey");
  const CompanyID = localStorage.getItem("CompanyID");
  const User = localStorage.getItem("Full_Name");

  const [CustomerForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [CustomerLoading, setCustomerLoading] = useState(false);
  const [ListOfRecords, setListOfRecords] = useState([]);
  const [TotalRecords, setTotalRecords] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);
  const [period, setPeriod] = useState("all");
  const [customerAccountCode, setCustomerAccountCode] = useState(null);
  const [ReceiptBodies, setReceiptBodies] = useState([]);
  const [AccountCode, setAccountCode] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [includeAllCustomers, setIncludeAllCustomers] = useState(false);
  const defaultStartDate = dayjs().format("YYYY-MM-DD");
  const defaultEndDate = dayjs().format("YYYY-MM-DD");

  const fetchReceipts = async () => {
    setLoading(true);
    try {
      const api_config = {
        method: "get",
        url: `${
          Config.base_url
        }Reports/GetReceiptTransactions/${CompanyID}?customerCode=${
          selectedCustomer ? selectedCustomer.accountNo : ""
        }`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessKey}`,
        },
      };

      const response = await axios(api_config);
      console.log(response.data);
      if (response.data.statusCode === 1) {
        // Transform the data
        const transformData = (receipts) => {
          return receipts.map((receipt) => ({
            key: receipt.voucherNo,
            id: receipt.id,
            voucherNo: receipt.voucherNo,
            date: receipt.date,
            customerName: receipt.customerName,
            receiptType: receipt.receiptType,
            amount: receipt.amount,
            total: receipt.total,
            children: receipt.receiptBodies
              ? receipt.receiptBodies.map((body) => ({
                  key: body.invoiceNo,
                  id: body.id,
                  voucherNo: body.voucherNo,
                  createdDate: dayjs(body.createdDate).format("YYYY-MM-DD"),
                  invoiceNo: body.invoiceNo,
                  receiptType: body.receiptType,
                  amount: body.amount,
                  total: body.total,
                }))
              : undefined,
          }));
        };
        // Update the state with transformed data
        setReceiptBodies(transformData(response.data.headTransactions));
        setShowTable(true);
      }
    } catch (err) {
      console.error(err);
      // Optional: Add error handling (e.g., a message to notify the user)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Customer Receipt Details";
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    setCustomerLoading(true);
    try {
      const response = await CustomerDropdown();
      if (response != null) {
        setListOfRecords(response);
      }
    } catch (error) {
      // console.error(error);
    } finally {
      setCustomerLoading(false);
    }
  };
  const customer = [...ListOfRecords].map(
    ({ businessName, accountCode, id, isCustomer, isSupplier, accountNo }) => {
      const trimmedBusinessName = businessName.trim();
      const parsedAccountCode = parseInt(accountCode);
      let accountLabel = accountNo;

      if (isSupplier && parsedAccountCode < 9000) {
        accountLabel += " (S)";
      } else if (isCustomer && parsedAccountCode > 9000) {
        accountLabel += " (C)";
      }

      return {
        label: `${trimmedBusinessName} (${accountLabel})`.trim(),
        value: id,
      };
    }
  );

  const handleSelectChange = async (value) => {
    if (value === 0) {
      setSelectedCustomer(null);
      setCustomerAccountCode("");
      setIncludeAllCustomers(true);
      return;
    }
    const selectedCustomer = ListOfRecords.find(
      (customer) => customer.id == value
    );
    if (selectedCustomer) {
      setIncludeAllCustomers(false);
      setSelectedCustomer(ListOfRecords.find((record) => record.id === value));
      setCustomerAccountCode(selectedCustomer.accountNo);
    }
  };

  const columns = [
    {
      title: "Voucher No",
      dataIndex: "voucherNo",
      key: "voucherNo",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "200px",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      width: "300px",
    },
    {
      title: "Type",
      dataIndex: "receiptType",
      key: "receiptType",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },

    {
      title: "Expand",
      key: "expand",
      dataIndex: "id",
      render: () => null,
      colSpan: 0,
      fixed: true,
    },
  ];

  const expandedRowColumns = [
    {
      title: "Invoice No",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
    },

    {
      title: "Date",
      dataIndex: "createdDate",
      key: "createdDate",
    },
    {
      title: "Type",
      dataIndex: "receiptType",
      key: "receiptType",
    },
    {
      title: "Voucher No",
      dataIndex: "voucherNo",
      key: "voucherNo",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  return (
    <>
      <div id="sub-menu-wrap">
        <h5>Reports</h5>
        <ReportsMenu />
      </div>

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <SubMenuToggle />
            <h3 className="page-title">Customer Receipt Details</h3>
            {showTable && ReceiptBodies.length > 0 && (
              <div className="header-actions">
                <PrintTable
                  selectedSupplier={selectedCustomer}
                  startDate={startDate}
                  endDate={endDate}
                  User={User}
                  title="Customer Receipt Details"
                />
              </div>
            )}
          </div>

          <div className="filters-wrap">
            <Form onFinish={fetchReceipts} form={CustomerForm}>
              <Form.Item name="customerName">
                <Select
                  style={{ width: "300px" }}
                  placeholder="Select Customer"
                  showSearch
                  optionFilterProp="label" // Specifies which prop should be filtered (label)
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase().includes(input.toLowerCase())
                  }
                  loading={CustomerLoading}
                  notFoundContent={
                    CustomerLoading ? <Spin size="small" /> : null
                  }
                  options={customer}
                  onSelect={handleSelectChange}
                />
              </Form.Item>

              <Form.Item
                name="customerAccountCode"
                label="Customer Account Code"
                hidden
              >
                <Input />
              </Form.Item>

              <Button type="primary" htmlType="submit" loading={loading}>
                Run Report
              </Button>
            </Form>
          </div>

          {showTable && ReceiptBodies.length > 0 && (
            <>
              <div className="reports-main-div">
                <div className="report-content">
                  <div className="report-left">
                    <h2>
                      {selectedCustomer
                        ? selectedCustomer.businessName
                        : "All Customers"}
                    </h2>
                    {selectedCustomer && (
                      <>
                        <h3>Account Code: {selectedCustomer.accountNo}</h3>
                      </>
                    )}
                  </div>
                  <img className="report-company-name" src={Logo} />
                  <div className="report-right">
                    <h2>Customer Receipt Details</h2>
                    <h3>Printed by: {User} </h3>
                    <h3>Printed on: {new Date().toLocaleString()}</h3>
                  </div>
                </div>
              </div>

              <Spin spinning={loading}>
                <Table
                  columns={columns}
                  dataSource={ReceiptBodies}
                  rowKey="id"
                  expandable={{
                    expandedRowRender: (record) => (
                      <div>
                        <Table
                          columns={expandedRowColumns}
                          dataSource={record.children}
                          pagination={false}
                          // rowKey="invoiceNo"
                        />
                      </div>
                    ),
                    rowExpandable: (record) =>
                      record.children && record.children.length > 0, // Only expandable if there are children
                  }}
                  pagination={{ pageSize: 20 }}
                  rowClassName="outer-row"
                  onRow={(record) => ({
                    onClick: () => {
                      // You can handle any row-level interaction here if necessary
                    },
                  })}
                />
              </Spin>
            </>
          )}

          {showTable && ReceiptBodies.length === 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}

          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>
    </>
  );
};

export default CustomerReceiptDetails;
