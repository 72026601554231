import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
  Spin,
  Table,
  Empty,
} from "antd";
import { PrinterOutlined, UploadOutlined } from "@ant-design/icons";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Config from "../../Config";
import SubMenuToggle from "../Common/SubMenuToggle";
import dayjs from "dayjs";
import Logo from "../../assets/images/logo/dark-h.svg";
import BankPrint from "../Shared/BankPrint";
import ExcelJS from "exceljs";
import ReportsMenu from "../Reports/ReportsMenu";
import LevelWiseAccount2 from "../Shared/LevelWiseAccount2";

const BankReports = () => {
  const navigate = useNavigate();
  const AccessKey = localStorage.getItem("AccessKey");
  const CompanyID = localStorage.getItem("CompanyID");
  const User = localStorage.getItem("Full_Name");

  const [BankForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [BankLoading, setBankLoading] = useState(false);
  const [ListOfRecords, setListOfRecords] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);
  const [period, setPeriod] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showTable, setShowTable] = useState(false); // New state variable
  const defaultStartDate = dayjs().format("YYYY-MM-DD");
  const defaultEndDate = dayjs().format("YYYY-MM-DD");

  const fetchBankTransactions = async (FormData) => {
    setLoading(true);
    setError(null);

    const effectiveStartDate =
      FormData.period === "custom" && FormData.startDate
        ? dayjs(FormData.startDate).format("YYYY-MM-DD")
        : defaultStartDate;

    const effectiveEndDate =
      FormData.period === "custom" && FormData.endDate
        ? dayjs(FormData.endDate).format("YYYY-MM-DD")
        : defaultEndDate;

    try {
      const api_config = {
        method: "get",
        url: `${Config.base_url}Reports/GetBankReportBy/${CompanyID}?bankName=${
          FormData.bankName
        }&otherBank=${
          FormData.bankName
        }&startDate=${effectiveStartDate}&endDate=${effectiveEndDate}&period=${
          FormData.period
        }&includeAllBanks=${FormData.bankName === "all"}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessKey}`,
        },
      };
      const response = await axios(api_config);
      //console.log(response.data);
      setTransactions(response.data);
      setShowTable(true); // Show table after fetching data
    } catch (err) {
      console.error("Error fetching customer transactions:", err);
      setError("Failed to load transactions.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Bank Report";
    fetchBankAccounts();
  }, []);

  const fetchBankAccounts = async () => {
    setBankLoading(true);
    try {
      const response = await LevelWiseAccount2(3, "50108");
      if (response != null) {
        setListOfRecords(response);
      }
    } catch (error) {
      // console.error(error);
    } finally {
      setBankLoading(false);
    }
  };

  const handleBankChange = (value) => {
    if (value === "all") {
      setSelectedBank(null); // Clear selected customer
      BankForm.setFieldsValue({
        accountCode: null,
      });
    } else {
      const bank = ListOfRecords.find(
        (bank) =>
          bank.accountDescription + " " + `(${bank.accountCode})` === value
      );
      setSelectedBank(bank);
      if (bank) {
        BankForm.setFieldsValue({
          accountCode: bank.accountCode,
        });
      }
    }
  };

  const handleDateChange = (date, dateString) => {
    setStartDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const handleDueDateChange = (date, dateString) => {
    setEndDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const handleExport = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Bank Report");

    const bankName = selectedBank
      ? `${selectedBank.accountDescription} (${selectedBank.accountCode})`
      : "All Banks";

    sheet.mergeCells("A1:J2"); // Merge cells for the title
    const titleCell = sheet.getCell("C3:G3");
    titleCell.value = `Bank Report: ${bankName}`;
    titleCell.font = { bold: true, size: 18 };
    titleCell.alignment = { vertical: "middle", horizontal: "center" };

    sheet.columns = [
      { header: "Type", key: "type", width: 20 },
      { header: "V. No", key: "voucherNo", width: 10 },
      { header: "Date", key: "date", width: 20 },
      { header: "Account", key: "account", width: 30 },
      { header: "Ref No", key: "refNo", width: 10 },
      { header: "Details", key: "details", width: 10 },
      { header: "Mode", key: "mode", width: 10 },
      { header: "Payments", key: "payments", width: 10 },
      { header: "Receipts", key: "receipts", width: 10 },
      { header: "Balance", key: "balance", width: 10 },
    ];

    const headerRow = sheet.addRow([
      "Type",
      "V. No",
      "Date",
      "Account",
      "Ref No",
      "Details",
      "Mode",
      "Payments",
      "Receipts",
      "Balance",
    ]);

    // Apply styling to the header row
    headerRow.font = { bold: true, color: { argb: "FFFFFFFF" } }; // White text color
    headerRow.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF324F94" }, // Background color #324F94 (blue)
    };

    let runningBalance = 0;
    let totalReceipt = 0;
    let totalPayment = 0;

    // Add rows to the sheet
    transactions.forEach((banks, index) => {
      const currentBalance = banks.receipts - banks.payments;

      // Update running balance and totals
      if (index === 0) {
        runningBalance = currentBalance;
      } else {
        runningBalance += currentBalance;
      }
      totalReceipt += banks.receipts;
      totalPayment += banks.payments;

      sheet.addRow({
        sr: index + 1,
        type: banks.type,
        voucherNo: banks.voucherNo,
        date: dayjs(banks.date).format("YYYY-MM-DD"),
        account: banks.account,
        refNo: banks.refNo,
        details: banks.details,
        mode: banks.mode,
        payments: banks.payments,
        receipts: banks.receipts,
        balance: runningBalance.toFixed(2),
      });
    });

    const totalsRow = sheet.addRow({
      account: "Totals", // Label for totals
      payments: totalPayment.toFixed(2),
      receipts: totalReceipt.toFixed(2),
      balance: runningBalance.toFixed(2),
    });

    // Apply styling to the totals row
    totalsRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: "right" };
      if (colNumber > 1) {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    });

    const now = new Date();
    const dateString = now
      .toLocaleString("sv-SE", { timeZoneName: "short" })
      .replace(/[^0-9]/g, "");

    // Generate the Excel file and prompt the user to download it
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `BanksReport_${dateString}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  let runningBalance = 0; // Initialize running balance

  const columns = [
    // {
    //     title: "Sr#",
    //     dataIndex: "",
    //     key: "SR",
    //     render: (_, record, index) => index + 1,
    // },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "V. No",
      dataIndex: "voucherNo",
      key: "voucherNo",
      sorter: (a, b) => a.voucherNo - b.voucherNo,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("YYYY-MM-DD"),
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
    },
    {
      title: "Account",
      dataIndex: "account",
      key: "account",
      sorter: (a, b) => a.account.localeCompare(b.account),
    },
    {
      title: "Ref No",
      dataIndex: "refNo",
      key: "refNo",
      sorter: (a, b) => a.refNo.localeCompare(b.refNo),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      sorter: (a, b) => a.details.localeCompare(b.details),
    },
    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
      sorter: (a, b) => a.mode.localeCompare(b.mode),
    },

    {
      title: "Payments",
      dataIndex: "payments",
      key: "payments",
      render: (value) => (value ? value.toFixed(2) : "0.00"),
      sorter: (a, b) => a.payments - b.payments,
    },
    {
      title: "Receipts",
      dataIndex: "receipts",
      key: "receipts",
      render: (value) => (value ? value.toFixed(2) : "0.00"),
      sorter: (a, b) => a.receipts - b.receipts,
    },

    {
      title: "Balance",
      dataIndex: "",
      key: "balance",
      render: (text, record, index) => {
        // Calculate the current record's balance
        const currentBalance = record.receipts - record.payments;

        // Add the current record's balance to the running balance
        if (index === 0) {
          runningBalance = currentBalance; // For the first row
        } else {
          runningBalance += currentBalance; // Add to the running balance for subsequent rows
        }

        // Return the updated running balance
        return <>{runningBalance.toFixed(2)}</>;
      },
      sorter: (a, b) => a.balance - b.balance,
    },
  ];

  const summary = () => {
    let totalDebit = 0;
    let totalCredit = 0;

    transactions.forEach(({ payments, receipts, balance }) => {
      totalDebit += payments || 0;
      totalCredit += receipts || 0;
    });

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={7}>Total</Table.Summary.Cell>
        <Table.Summary.Cell>{totalDebit.toFixed(2)}</Table.Summary.Cell>
        <Table.Summary.Cell>{totalCredit.toFixed(2)}</Table.Summary.Cell>
        <Table.Summary.Cell>
          {selectedBank !== null ? (totalCredit - totalDebit).toFixed(2) : ""}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  return (
    <>
      <div id="sub-menu-wrap">
        <h5>Reports</h5>
        <ReportsMenu />
      </div>

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <SubMenuToggle />
            <h3 className="page-title">
              <NavLink to="/bank/manage">
                <ArrowLeftIcon />
              </NavLink>
              Bank Report
            </h3>
            {showTable && transactions.length > 0 && (
              <div className="header-actions">
                <Button
                  type="dashed"
                  onClick={handleExport}
                  icon={<UploadOutlined />}
                >
                  Export Report
                </Button>
                <BankPrint
                  selectedSupplier={selectedBank}
                  startDate={startDate}
                  endDate={endDate}
                  User={User}
                  title="Bank Report"
                />
              </div>
            )}
          </div>

          <div className="filters-wrap">
            <Form form={BankForm} onFinish={fetchBankTransactions}>
              <Form.Item name="bankName">
                <Select
                  placeholder="Select Bank"
                  style={{ width: "300px" }}
                  loading={BankLoading}
                  showSearch
                  filterOption={(input, option) =>
                    option.value.toLowerCase().includes(input.toLowerCase())
                  }
                  notFoundContent={BankLoading ? <Spin size="small" /> : null}
                  onSelect={handleBankChange}
                >
                  <Select.Option value="all">All Banks</Select.Option>
                  {ListOfRecords.map((bank) => (
                    <Select.Option
                      key={bank.accountNo}
                      value={`${bank.accountDescription} (${bank.accountCode})`}
                    >
                      {bank.accountDescription} ({bank.accountCode})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="period"
                style={{ width: "150px" }}
                onChange={(value) => setPeriod(value)}
                initialValue="year"
              >
                <Select placeholder="Date Range">
                  <Select.Option value="all">All Dates</Select.Option>
                  <Select.Option value="custom">Custom</Select.Option>
                  <Select.Option value="today">Today</Select.Option>
                  <Select.Option value="week">This Week</Select.Option>
                  <Select.Option value="month">This Month</Select.Option>
                  <Select.Option value="last60Days">Last 60 Days</Select.Option>
                  <Select.Option value="year">This Year</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="accountCode" label="Account Code" hidden>
                <Input onFocus={(e) => e.target.select()} />
              </Form.Item>

              <Form.Item name="startDate">
                <DatePicker
                  value={startDate ? dayjs(startDate, "YYYY-MM-DD") : null}
                  onChange={handleDateChange}
                />
              </Form.Item>
              <Form.Item name="endDate">
                <DatePicker
                  value={endDate ? dayjs(endDate, "YYYY-MM-DD") : null}
                  onChange={handleDueDateChange}
                />
              </Form.Item>

              <Button type="primary" htmlType="submit" loading={loading}>
                Run Report
              </Button>
            </Form>
          </div>

          {showTable && transactions.length > 0 && (
            <>
              <div className="reports-main-div">
                {/* Main Content */}

                <div className="report-content">
                  {/* Left: Account Code and Opening Balance */}
                  <div className="report-left">
                    <h2>
                      {selectedBank
                        ? selectedBank.accountDescription
                        : "All Banks"}
                    </h2>
                    {selectedBank && (
                      <>
                        <h3>Bank Code: {selectedBank.accountCode}</h3>
                      </>
                    )}
                  </div>
                  <img className="report-company-name" src={Logo} />
                  {/* Right: User Name and Printed Date */}
                  <div className="report-right">
                    <h2>Banks Report</h2>
                    <h3>Printed by: {User} </h3>
                    <h3>Printed on: {new Date().toLocaleString()}</h3>
                  </div>
                </div>
              </div>
              <Table
                columns={columns}
                dataSource={transactions}
                rowKey="id"
                summary={summary}
                pagination={false}
              />
            </>
          )}

          {showTable && transactions.length === 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}

          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>
    </>
  );
};

export default BankReports;
