import React, { useState, useRef, useEffect, useCallback } from "react";
import { debounce, throttle } from "lodash";
import {
  DeleteOutlined,
  DownOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Skeleton,
  Select,
  message,
  Divider,
  Space,
  Collapse,
  Dropdown,
  Spin,
  Table,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import { Link, NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import Config from "../../../Config";
import SubMenuToggle from "../../Common/SubMenuToggle";
import AssemblyMenu from "../AssemblyMenu";
import { async } from "q";
import AddSupplierModal from "../../Common/AddSupplierModal";
import AddProductModal from "../../Common/AddProductModal";
import FormItem from "antd/es/form/FormItem";
import ProductDropdown from "../../Shared/ProductDropdown";
import LevelWiseAccount2 from "../../Shared/LevelWiseAccount2";
import TemplateDropdown from "../../Shared/TemplateDropdown";
import LocationDropdown from "../../Shared/LocationDropdown";

const { Option } = Select;

const AddJob = () => {
  const navigate = useNavigate();
  const AccessKey = localStorage.getItem("AccessKey");
  const UserName = localStorage.getItem("Full_Name");
  const UserID = localStorage.getItem("ID");
  const CompanyID = localStorage.getItem("CompanyID");
  const FYear = localStorage.getItem("DefaultFYear");

  const [ExpenseForm] = Form.useForm();
  const [nonStockForm] = Form.useForm();
  const [DetailsForm] = Form.useForm();
  const [RmForm] = Form.useForm();
  const [form] = Form.useForm();
  const [FinishedGoodsForm] = Form.useForm();
  const [formMain] = Form.useForm();

  const [ListOfAccounts, setListOfAcconts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [CustomerLoading, setCustomerLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [ProductID, setProductID] = useState("");
  const [productOpen, setProductOpen] = useState(false);
  const [bankLoading, setBankLoading] = useState(false);

  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalLength, setTotalLength] = useState(0);

  const [ListOfRecords, setListOfRecords] = useState([]);
  const [ListOfProducts, setListOfProducts] = useState([]);
  const [TempList, setTempList] = useState([]);
  const [LocationList, setLocationList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [TempLoading, setTempLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [Label, setLabel] = useState("");
  const [LocationLabel, setLocationLabel] = useState("");
  const [LocationID, setLocationID] = useState("");
  const [LocationCode, setLocationCode] = useState("");

  const handleSubmit = async (FormData) => {
    setLoading(true);
    if (!FormData.templateName || FormData.templateName == "") {
      message.error("Please enter valid Template Name");
      return;
    }

    const fields = FinishedGoodsForm.getFieldValue("users");
    if (fields.length === 0) {
      message.error("Please add at least one Product in Finished Goods.");
      return;
    }

    const RmData = RmForm.getFieldValue("users");
    if (RmData.length === 0) {
      message.error("Please add at least one Product in Raw Material.");
      return;
    }

    const NonStockData = nonStockForm.getFieldValue("users");
    if (NonStockData.length === 0) {
      message.error("Please add at least one Product in Non Stock.");
      return;
    }

    const ExpenseData = ExpenseForm.getFieldValue("users");
    if (ExpenseData.length === 0) {
      message.error("Please add data in Expense.");
      return;
    }

    const DetailsData = {
      ...FormData,
      templateName: Label,
      startDate: startDate || dayjs().format("YYYY-MM-DD"),
      status: "In Progress",
      rmFactor: FormData.rmFactor || 0,
      refNo: FormData.refNo || "",
      location: LocationLabel,
      locationID: LocationID,
      locationCode: LocationCode,
      assemblyType: "Job",
      CompanyID: CompanyID,
      isActive: true,
      isDeleted: false,
      UserID: UserID,
    };

    const FinishedGoods = fields.map((item) => ({
      ...item,
      unit: item.unit || "",
      assemblyType: "Job",
      CompanyID: CompanyID,
      isActive: true,
      isDeleted: false,
      UserID: UserID,
    }));

    const RawMaterial = RmData.map((item) => ({
      ...item,
      unit: item.unit || "",
      location: item.location || "",
      assemblyType: "Job",
      CompanyID: CompanyID,
      isActive: true,
      isDeleted: false,
      UserID: UserID,
    }));

    const NonStock = NonStockData.map((item) => ({
      ...item,
      unit: item.unit || "",
      location: item.location || "",
      assemblyType: "Job",
      CompanyID: CompanyID,
      isActive: true,
      isDeleted: false,
      UserID: UserID,
    }));

    const Expense = ExpenseData.map((item) => ({
      ...item,
      assemblyType: "Job",
      CompanyID: CompanyID,
      isActive: true,
      isDeleted: false,
      UserID: UserID,
    }));

    const data = {
      tempDetails: DetailsData,
      finishedGoods: FinishedGoods,
      rawMaterials: RawMaterial,
      nonStocks: NonStock,
      expenses: Expense,
    };
    console.log(data);
    try {
      const response = await axios.post(`${Config.base_url}Jobs/AddJob`, data, {
        headers: {
          Authorization: `Bearer ${AccessKey}`,
        },
      });
      if (response.data.status_code == 1) {
        message.success(response.data.status_message);
        setLoading(false);
        DetailsForm.resetFields();
        FinishedGoodsForm.resetFields();
        RmForm.resetFields();
        nonStockForm.resetFields();
        ExpenseForm.resetFields();
      } else {
        message.error(response.data.status_message);
        setLoading(false);
      }
    } catch (error) {
      message.error("Network Error..");
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Add Job";
    fetchTemplates();
    fetchLocations();
    fetchProducts();
    fetchBankAccounts();
  }, []);

  const fetchTemplates = async () => {
    setTempLoading(true);
    try {
      const response = await TemplateDropdown();
      if (response != null) {
        setTempList(response || []);
      }
      //console.log(response);
    } catch (error) {
      // console.error(error);
    } finally {
      setTempLoading(false);
    }
  };

  const fetchLocations = async () => {
    setTempLoading(true);
    try {
      const response = await LocationDropdown();
      if (response != null) {
        setLocationList(response || []);
      }
      //console.log(response);
    } catch (error) {
      // console.error(error);
    } finally {
      setTempLoading(false);
    }
  };

  const location = LocationList.map((record) => ({
    label: record.locationName,
    value: record.id,
    code: record.locationCode,
  }));

  const handleLocationChange = async (value) => {
    const selectedLocation = location.find(
      (location) => location.value === value
    );
    console.log(selectedLocation.label);
    console.log(selectedLocation.value);
    console.log(selectedLocation.code);
    setLocationLabel(selectedLocation.label);
    setLocationID(selectedLocation.value);
    setLocationCode(selectedLocation.code);
  };

  const template = TempList.map((record) => ({
    label: record.tempName,
    value: record.tempID,
  }));

  const handleSelectChange = async (value) => {
    const selectedTemplate = template.find(
      (template) => template.value === value
    );
    //console.log(selectedTemplate.label);
    setLabel(selectedTemplate.label);
    if (selectedTemplate) {
      await fetchTemplateByID(selectedTemplate.value);
    }
  };

  const fetchTemplateByID = async (id) => {
    setTempLoading(true);
    try {
      const api_config = {
        method: "get",
        url: `${Config.base_url}Templates/GetTemplateForEditBy/${CompanyID}?iD=${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessKey}`,
        },
      };
      const response = await axios(api_config);
      if (response.data && response.data.status_code === 1) {
        setSelectedTemplate(response.data.details);
        //DetailsForm.setFieldsValue(response.data.details);
        FinishedGoodsForm.setFieldsValue({
          users: response.data.listofFinishedGoods,
        });
        RmForm.setFieldsValue({ users: response.data.listofRawMaterial });
        nonStockForm.setFieldsValue({
          users: response.data.listofNonStock,
        });
        ExpenseForm.setFieldsValue({ users: response.data.listofExpenses });
        setTempLoading(false);
      } else {
        message.error(response.data.status_message);
      }
    } catch (error) {
      message.error("Network Error..");
      setTempLoading(false);
    }
  };

  const fetchProducts = async () => {
    setProductLoading(true);
    try {
      const response = await ProductDropdown();
      if (response != null) {
        setListOfProducts(response || []);
      }
    } catch (error) {
      // console.error(error);
    } finally {
      setProductLoading(false);
    }
  };

  const fetchBankAccounts = async () => {
    setBankLoading(true);
    try {
      const response = await LevelWiseAccount2(3, "70");
      if (response != null) {
        setListOfRecords(response);
      }
    } catch (error) {
      // console.error(error);
    } finally {
      setBankLoading(false);
    }
  };

  const handleDateChange = (e, value) => {
    setStartDate(value);
  };

  const handleProductOk = (FormData) => {
    setLoading(true);
    setProductOpen(false);
    setLoading(false);
  };

  const handleProductCancel = () => {
    setProductOpen(false);
  };

  const Details = () => {
    return (
      <>
        <Form layout="vertical" form={DetailsForm} onFinish={handleSubmit}>
          <Row gutter={[24, 0]}>
            <Col xs={24} md={8}>
              <FormItem name="templateName" label="Template Name">
                <Select
                  showSearch
                  placeholder="Template Name"
                  loading={TempLoading}
                  notFoundContent={TempLoading ? <Spin size="small" /> : null}
                  value={Label}
                  // labelInValue={true}
                  onChange={handleSelectChange}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  options={template}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem name="rmFactor" label="RM Factor" initialValue="1">
                <Input placeholder="Qty" type="Number" />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem name="costCalType" label="Cost %" initialValue="Auto">
                <Select>
                  <Select.Option value="auto">Auto</Select.Option>
                  <Select.Option value="manual">Manual</Select.Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={24} md={8}>
              <Form.Item name="startDate" label="Start Date">
                <DatePicker
                  placeholder="Select Start Date"
                  style={{ width: "100%" }}
                  defaultValue={
                    startDate ? dayjs(startDate, "YYYY-MM-DD") : dayjs()
                  }
                  onChange={handleDateChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <FormItem name="refNo" label="Ref No">
                <Input placeholder="Ref No" />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem name="location" label="Location">
                <Select
                  showSearch
                  placeholder="Select Location"
                  loading={TempLoading}
                  notFoundContent={TempLoading ? <Spin size="small" /> : null}
                  value={LocationLabel}
                  // labelInValue={true}
                  onChange={handleLocationChange}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  options={location}
                />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const FinishedGoods = () => {
    const handleSelectChange = (value, index) => {
      const selectedProduct = ListOfProducts.find(
        (item) => item.name === value
      );
      //console.log(selectedProduct);
      if (selectedProduct) {
        const fields = FinishedGoodsForm.getFieldValue("users");
        const quantity = fields[index].quantity || 0;
        const rate = selectedProduct.salePrice;
        const amount = (quantity * rate).toFixed(2);
        const discount = (amount * selectedProduct.saleDiscount) / 100;
        const saleTax = (selectedProduct.gstRate * amount) / 100;
        const net = (amount - discount + saleTax).toFixed(2);

        FinishedGoodsForm.setFieldsValue({
          users: fields.map((field, i) =>
            i === index
              ? {
                  ...field,
                  description: `${selectedProduct.name} ${selectedProduct.saleInformation}`,
                  unit: selectedProduct.unit,
                  productID: selectedProduct.id,
                  productCode: selectedProduct.code,
                }
              : field
          ),
        });
        console.log(selectedProduct.code);
        //debouncedHandleFormChange();
      } else {
        console.error("Selected product not found in ListOfProducts:", value);
      }
    };

    const handleQuantityChange = (e, index) => {
      const quantity = parseFloat(e.target.value);
      const fields = form.getFieldValue("users");
      const formInstance = fields[index];

      if (isNaN(quantity) || quantity <= 0) {
        //console.log("Invalid quantity value");
        return;
      }

      if (formInstance.defaultUnit === "Quantity") {
        const amount = (quantity * formInstance.rate).toFixed(2) || 0;
        const discountAmt = (formInstance.discPercentege * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          quantity,
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };

        form.setFieldsValue({
          users: fields,
        });

        debouncedHandleFormChange();
      } else {
        //console.log("Rate unit is not Quantity");
      }
    };

    const handleWeightChange = (e, index) => {
      const weight = parseFloat(e.target.value);
      const fields = form.getFieldValue("users");
      const formInstance = fields[index];

      if (isNaN(weight) || weight <= 0) {
        //console.log("Invalid weight value");
        return;
      }

      if (formInstance.defaultUnit === "Weight") {
        const amount = (weight * formInstance.rate).toFixed(2) || 0;
        const discountAmt = (formInstance.discPercentege * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          weight,
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };

        form.setFieldsValue({
          users: fields,
        });

        debouncedHandleFormChange();
      } else {
        //console.log("Rate unit is not Weight");
      }
    };

    const handleLengthChange = (e, index) => {
      const length = parseFloat(e.target.value);
      const fields = form.getFieldValue("users");
      const formInstance = fields[index];

      if (isNaN(length) || length <= 0) {
        //console.log("Invalid length value");
        return;
      }

      if (formInstance.defaultUnit === "Length") {
        const amount = (length * formInstance.rate).toFixed(2) || 0;
        const discountAmt = (formInstance.discPercentege * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          length,
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };

        form.setFieldsValue({
          users: fields,
        });

        debouncedHandleFormChange();
      } else {
        //console.log("Rate unit is not Length");
      }
    };

    const handleRateChange = (e, index) => {
      const fields = form.getFieldValue("users");

      const formInstance = fields[index];
      const rate = parseFloat(e.target.value) || 0;
      const quantity = parseFloat(formInstance.quantity) || 0;
      const length = parseFloat(formInstance.length) || 0;
      const weight = parseFloat(formInstance.weight) || 0;
      const discountPercentage = parseFloat(formInstance.discPercentege) || 0;

      if (formInstance.defaultUnit === "Length") {
        const amount = length * rate;
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };
        form.setFieldsValue({
          users: fields,
        });
        debouncedHandleFormChange();
      } else if (formInstance.defaultUnit === "Weight") {
        const amount = weight * rate;
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };
        form.setFieldsValue({
          users: fields,
        });
        debouncedHandleFormChange();
      } else if (formInstance.defaultUnit === "Quantity") {
        const amount = quantity * rate;
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };
        form.setFieldsValue({
          users: fields,
        });
        debouncedHandleFormChange();
      } else {
        console.error("Invalid product selection or quantity");
      }
    };

    const columns = (remove) => [
      { title: "Sr No", dataIndex: "srNo", key: "srNo" },
      {
        title: "Product / Services",
        dataIndex: "productName",
        key: "productName",
        width: 300,
      },
      {
        title: "",
        dataIndex: "productID",
        key: "productID",
        width: 0,
      },

      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: 250,
      },
      { title: "Qty FI %", dataIndex: "qtyfi", key: "qtyfi", width: 180 },
      { title: "Unit", dataIndex: "unit", key: "unit", width: 180 },
      { title: "Qty", dataIndex: "quantity", key: "quantity", width: 180 },
      {
        title: "Cost FI %",
        dataIndex: "costFi",
        key: "costFi",
        width: 180,
      },
      {
        title: "",
        dataIndex: "productCode",
        key: "productCode",
        width: 0,
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 110,
        render: (_, { key }) => (
          <ul className="inline-action">
            <li>
              <Link to={`#/`} onClick={() => remove(key)} className="red">
                <DeleteOutlined />
              </Link>
            </li>
          </ul>
        ),
      },
    ];
    const debouncedHandleFormChange = useCallback(
      debounce(() => {
        const fields = form.getFieldValue("users");
        const quantity = fields.map((item) => parseFloat(item.quantity) || 0);
        const weight = fields.map((item) => parseFloat(item.weight) || 0);
        const length = fields.map((item) => parseFloat(item.length) || 0);
        const amounts = fields.map((item) => parseFloat(item.amount) || 0);
        const discount = fields.map((item) => parseFloat(item.discount) || 0);
        const tax = fields.map((item) => parseFloat(item.saleTax) || 0);
        const net = fields.map((item) => parseFloat(item.net) || 0);

        const totalQuantity = quantity.reduce((sum, value) => sum + value, 0);
        const totalWeight = weight.reduce((sum, value) => sum + value, 0);
        const totalLength = length.reduce((sum, value) => sum + value, 0);
        const totalAmount = amounts.reduce((sum, value) => sum + value, 0);
        const totalDiscount = discount.reduce((sum, value) => sum + value, 0);
        const totalSaleTax = tax.reduce((sum, value) => sum + value, 0);
        const total = net.reduce((sum, value) => sum + value, 0);

        console.log(totalQuantity, totalWeight, totalLength, totalAmount);
        setTotalQuantity(totalQuantity);
        setTotalWeight(totalWeight);
        setTotalLength(totalLength);
        setTotalDiscount(totalAmount);
        DetailsForm.setFieldsValue({
          subTotal: totalAmount,
          totalDiscount: totalDiscount,
          totalSaleTax: totalSaleTax,
          total: total,
        });
      }, 1000),
      []
    );

    return (
      <>
        <Form
          form={FinishedGoodsForm}
          name="dynamic_form_nest_item"
          onFinish={handleSubmit}
          autoComplete="off"
          initialValues={{
            users: [{}],
          }}
        >
          <Form.List name="users">
            {(fields, { add, remove }) => (
              <>
                <Table
                  dataSource={fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => ({
                      srNo: index + 1,
                      key,
                      productName: (
                        <Form.Item
                          {...restField}
                          name={[name, "productName"]}
                          fieldKey={[fieldKey, "productName"]}
                          style={{ width: "300px" }}
                        >
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(value) => {
                              handleSelectChange(value, index);
                            }}
                            placeholder="Product / Services"
                            variant="borderless"
                            notFoundContent={
                              productLoading ? <Spin size="small" /> : ""
                            }
                            loading={productLoading}
                            options={ListOfProducts.map((record) => ({
                              label: (
                                <>
                                  {record.name} - Q : {record.openingQuantity}
                                </>
                              ),
                              value: record.name,
                            }))}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Space
                                  style={{
                                    padding: "0 8px 4px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Button
                                    type="text"
                                    icon={<PlusOutlined />}
                                    onClick={() => setProductOpen(true)}
                                  >
                                    Add Product
                                  </Button>
                                </Space>
                              </>
                            )}
                          />
                        </Form.Item>
                      ),

                      unit: (
                        <Form.Item
                          {...restField}
                          name={[name, "unit"]}
                          fieldKey={[fieldKey, "unit"]}
                        >
                          <Input
                            placeholder="Unit"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                          />
                        </Form.Item>
                      ),
                      description: (
                        <Form.Item
                          {...restField}
                          name={[name, "description"]}
                          fieldKey={[fieldKey, "description"]}
                        >
                          <Input
                            placeholder="Description"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            //onChange={(e) => handleQuantityChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      qtyfi: (
                        <Form.Item
                          {...restField}
                          name={[name, "qtyfi"]}
                          fieldKey={[fieldKey, "qtyfi"]}
                        >
                          <Input
                            type="Number"
                            placeholder="Qty FI %"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            //onChange={(e) => handleWeightChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      quantity: (
                        <Form.Item
                          {...restField}
                          name={[name, "quantity"]}
                          fieldKey={[fieldKey, "quantity"]}
                        >
                          <Input
                            placeholder="Qty"
                            type="Number"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            //onChange={(e) => handleLengthChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      costFi: (
                        <Form.Item
                          {...restField}
                          name={[name, "costFI"]}
                          fieldKey={[fieldKey, "costFI"]}
                        >
                          <Input
                            placeholder="Cost FI %"
                            type="Number"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            //onChange={(e) => handleRateChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      action: (
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      ),
                    })
                  )}
                  columns={columns(remove)}
                  pagination={false}
                  size="small"
                  summary={() => (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>
                        Totals:{" "}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}></Table.Summary.Cell>
                      <Table.Summary.Cell index={5}></Table.Summary.Cell>
                      <Table.Summary.Cell index={6}>
                        {totalLength}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7}>
                        {totalWeight}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8}></Table.Summary.Cell>
                      <Table.Summary.Cell index={9}></Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                />

                <Form.Item>
                  <Button
                    style={{ marginTop: 10 }}
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </>
    );
  };

  const RawMaterial = () => {
    const handleSelectChange = (value, index) => {
      const selectedProduct = ListOfProducts.find(
        (item) => item.name === value
      );
      //console.log(selectedProduct);
      if (selectedProduct) {
        const fields = RmForm.getFieldValue("users");
        const quantity = fields[index].quantity || 0;
        const rate = selectedProduct.salePrice;
        const amount = (quantity * rate).toFixed(2);
        const discount = (amount * selectedProduct.saleDiscount) / 100;
        const saleTax = (selectedProduct.gstRate * amount) / 100;
        const net = (amount - discount + saleTax).toFixed(2);

        RmForm.setFieldsValue({
          users: fields.map((field, i) =>
            i === index
              ? {
                  ...field,
                  description: `${selectedProduct.name} ${selectedProduct.saleInformation}`,
                  unit: selectedProduct.unit,
                  productID: selectedProduct.id,
                  productCode: selectedProduct.code,
                }
              : field
          ),
        });
        console.log(selectedProduct.code);
        //debouncedHandleFormChange();
      } else {
        console.error("Selected product not found in ListOfProducts:", value);
      }
    };
    const columns = (remove) => [
      { title: "Sr No", dataIndex: "srNo", key: "srNo" },
      {
        title: "Product / Services",
        dataIndex: "productName",
        key: "productName",
        width: 300,
      },

      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: 250,
      },
      { title: "Location", dataIndex: "location", key: "location", width: 250 },
      { title: "Unit", dataIndex: "unit", key: "unit", width: 180 },
      {
        title: "Stock In Hand",
        dataIndex: "stockInHand",
        key: "stockInHand",
        width: 180,
      },
      {
        title: "Per Unit",
        dataIndex: "perUnit",
        key: "perUnit",
        width: 180,
      },
      {
        title: "Qty Required",
        dataIndex: "qtyRequired",
        key: "qtyRequired",
        width: 180,
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 110,
        render: (_, { key }) => (
          <ul className="inline-action">
            <li>
              <Link to={`#/`} onClick={() => remove(key)} className="red">
                <DeleteOutlined />
              </Link>
            </li>
          </ul>
        ),
      },
    ];
    return (
      <>
        <Form
          form={RmForm}
          name="dynamic_form_nest_item"
          onFinish={handleSubmit}
          autoComplete="off"
          initialValues={{
            users: [{}],
          }}
        >
          <Form.List name="users">
            {(fields, { add, remove }) => (
              <>
                <Table
                  dataSource={fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => ({
                      srNo: index + 1,
                      key,
                      productName: (
                        <Form.Item
                          {...restField}
                          name={[name, "productName"]}
                          fieldKey={[fieldKey, "productName"]}
                          style={{ width: "250px" }}
                        >
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              option.value
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(value) => {
                              handleSelectChange(value, index);
                            }}
                            placeholder="Product / Services"
                            variant="borderless"
                            loading={productLoading}
                            options={ListOfProducts.map((record) => ({
                              label: (
                                <>
                                  {record.name} - Q : {record.openingQuantity}
                                </>
                              ),
                              value: record.name,
                            }))}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Space
                                  style={{
                                    padding: "0 8px 4px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Button
                                    type="text"
                                    icon={<PlusOutlined />}
                                    onClick={() => setProductOpen(true)}
                                  >
                                    Add Product
                                  </Button>
                                </Space>
                              </>
                            )}
                          />
                        </Form.Item>
                      ),

                      unit: (
                        <Form.Item
                          {...restField}
                          name={[name, "unit"]}
                          fieldKey={[fieldKey, "unit"]}
                        >
                          <Input
                            placeholder="Unit"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                          />
                        </Form.Item>
                      ),
                      description: (
                        <Form.Item
                          {...restField}
                          name={[name, "description"]}
                          fieldKey={[fieldKey, "description"]}
                        >
                          <Input
                            placeholder="Description"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            //onChange={(e) => handleQuantityChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      location: (
                        <Form.Item
                          {...restField}
                          name={[name, "location"]}
                          fieldKey={[fieldKey, "location"]}
                        >
                          <Input
                            placeholder="Location"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            //onChange={(e) => handleWeightChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      stockInHand: (
                        <Form.Item
                          {...restField}
                          name={[name, "stockInHand"]}
                          fieldKey={[fieldKey, "stockInHand"]}
                        >
                          <Input
                            placeholder="Stock In Hand"
                            type="Number"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            //onChange={(e) => handleLengthChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      perUnit: (
                        <Form.Item
                          {...restField}
                          name={[name, "perUnit"]}
                          fieldKey={[fieldKey, "perUnit"]}
                        >
                          <Input
                            placeholder="Per Unit"
                            type="Number"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            //onChange={(e) => handleRateChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      qtyRequired: (
                        <Form.Item
                          {...restField}
                          name={[name, "qtyRequired"]}
                          fieldKey={[fieldKey, "qtyRequired"]}
                        >
                          <Input
                            placeholder="Qty Required"
                            type="Number"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            //onChange={(e) => handleRateChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      action: (
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      ),
                    })
                  )}
                  columns={columns(remove)}
                  pagination={false}
                  size="small"
                />

                <Form.Item>
                  <Button
                    style={{ marginTop: 10 }}
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </>
    );
  };

  const NonStock = () => {
    const handleSelectChange = (value, index) => {
      const selectedProduct = ListOfProducts.find(
        (item) => item.name === value
      );
      //console.log(selectedProduct);
      if (selectedProduct) {
        const fields = nonStockForm.getFieldValue("users");
        const quantity = fields[index].quantity || 0;
        const rate = selectedProduct.salePrice;
        const amount = (quantity * rate).toFixed(2);
        const discount = (amount * selectedProduct.saleDiscount) / 100;
        const saleTax = (selectedProduct.gstRate * amount) / 100;
        const net = (amount - discount + saleTax).toFixed(2);

        nonStockForm.setFieldsValue({
          users: fields.map((field, i) =>
            i === index
              ? {
                  ...field,
                  details: `${selectedProduct.name} ${selectedProduct.saleInformation}`,
                  productID: selectedProduct.id,
                  productCode: selectedProduct.code,
                }
              : field
          ),
        });
        console.log(selectedProduct.code);
        //debouncedHandleFormChange();
      } else {
        console.error("Selected product not found in ListOfProducts:", value);
      }
    };
    const columns = (remove) => [
      { title: "Sr No", dataIndex: "srNo", key: "srNo" },
      {
        title: "Product",
        dataIndex: "productName",
        key: "productName",
        width: 250,
      },

      {
        title: "Details",
        dataIndex: "details",
        key: "details",
        width: 250,
      },
      {
        title: "Qty Per Unit",
        dataIndex: "quantityPerUnit",
        key: "quantityPerUnit",
        width: 200,
      },
      { title: "Rate", dataIndex: "rate", key: "rate", width: 200 },
      {
        title: "Qty Required",
        dataIndex: "qtyRequired",
        key: "qtyRequired",
        width: 200,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        width: 200,
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 110,
        render: (_, { key }) => (
          <ul className="inline-action">
            <li>
              <Link to={`#/`} onClick={() => remove(key)} className="red">
                <DeleteOutlined />
              </Link>
            </li>
          </ul>
        ),
      },
    ];

    return (
      <>
        <Form
          form={nonStockForm}
          name="dynamic_form_nest_item"
          onFinish={handleSubmit}
          autoComplete="off"
          initialValues={{
            users: [{}],
          }}
        >
          <Form.List name="users">
            {(fields, { add, remove }) => (
              <>
                <Table
                  dataSource={fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => ({
                      srNo: index + 1,
                      key,
                      productName: (
                        <Form.Item
                          {...restField}
                          name={[name, "productName"]}
                          fieldKey={[fieldKey, "productName"]}
                          style={{ width: "250px" }}
                        >
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              option.value
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(value) => {
                              handleSelectChange(value, index);
                            }}
                            placeholder="Product"
                            variant="borderless"
                            loading={productLoading}
                            options={ListOfProducts.filter(
                              (record) => record.productType === "NonStock"
                            ).map((record) => ({
                              label: <>{record.name}</>,
                              value: record.name,
                            }))}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Space
                                  style={{
                                    padding: "0 8px 4px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                ></Space>
                              </>
                            )}
                          />
                        </Form.Item>
                      ),

                      details: (
                        <Form.Item
                          {...restField}
                          name={[name, "details"]}
                          fieldKey={[fieldKey, "details"]}
                        >
                          <Input
                            placeholder="Details"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                          />
                        </Form.Item>
                      ),
                      quantityPerUnit: (
                        <Form.Item
                          {...restField}
                          name={[name, "quantityPerUnit"]}
                          fieldKey={[fieldKey, "quantityPerUnit"]}
                        >
                          <Input
                            placeholder="Qty Per Unit"
                            type="Number"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            //onChange={(e) => handleQuantityChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      rate: (
                        <Form.Item
                          {...restField}
                          name={[name, "rate"]}
                          fieldKey={[fieldKey, "rate"]}
                        >
                          <Input
                            placeholder="Rate"
                            type="Number"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            //onChange={(e) => handleWeightChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      amount: (
                        <Form.Item
                          {...restField}
                          name={[name, "amount"]}
                          fieldKey={[fieldKey, "amount"]}
                        >
                          <Input
                            placeholder="Amount"
                            type="Number"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            //onChange={(e) => handleLengthChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      qtyRequired: (
                        <Form.Item
                          {...restField}
                          name={[name, "qtyRequired"]}
                          fieldKey={[fieldKey, "qtyRequired"]}
                        >
                          <Input
                            placeholder="Qty Required"
                            type="Number"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            //onChange={(e) => handleRateChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      action: (
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      ),
                    })
                  )}
                  columns={columns(remove)}
                  pagination={false}
                  size="small"
                />

                <Form.Item>
                  <Button
                    style={{ marginTop: 10 }}
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </>
    );
  };

  const ExpenseDetail = () => {
    const handleSelectChange = (value, index) => {
      const selectedAccount = ListOfRecords.find(
        (item) => item.accountDescription === value
      );
      //console.log(selectedAccount);
      if (selectedAccount) {
        const fields = ExpenseForm.getFieldValue("users");
        const quantity = fields[index].quantity || 0;
        const rate = selectedAccount.salePrice;
        const amount = (quantity * rate).toFixed(2);
        const discount = (amount * selectedAccount.saleDiscount) / 100;
        const saleTax = (selectedAccount.gstRate * amount) / 100;
        const net = (amount - discount + saleTax).toFixed(2);

        ExpenseForm.setFieldsValue({
          users: fields.map((field, i) =>
            i === index
              ? {
                  ...field,
                  details: `${selectedAccount.accountDescription} (${selectedAccount.accountCode})`,
                  expenseAccountID: selectedAccount.id,
                }
              : field
          ),
        });
        console.log(selectedAccount.accountCode);
        //debouncedHandleFormChange();
      } else {
        console.error("Selected Account not found in ListOfRecords:", value);
      }
    };
    const handleAmountChange = (e, index) => {
      const fields = ExpenseForm.getFieldValue("users");

      const formInstance = fields[index];
      const amount = parseFloat(e.target.value) || 0;
      const discountPercentage = parseFloat(formInstance.discPercentege) || 0;

      if (amount > 0) {
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };
        ExpenseForm.setFieldsValue({
          users: fields,
        });
        debouncedHandleFormChange();
      } else {
        message.error("Please Enter Amount");
      }
    };

    const handleDiscountChange = (e, index) => {
      const fields = ExpenseForm.getFieldValue("users");

      const formInstance = fields[index];
      const amount = formInstance.amount;
      const discountPercentage = parseFloat(e.target.value) || 0;
      if (discountPercentage > 100) {
        message.error("Discount cannot more than 100.");
        return;
      }
      const gst = parseFloat(formInstance.saleTax) || 0;

      if (amount > 0) {
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const net = (amount - discountAmt + gst).toFixed(2);

        fields[index] = {
          ...fields[index],
          discount: discountAmt,
          net,
        };
        ExpenseForm.setFieldsValue({
          users: fields,
        });
        debouncedHandleFormChange();
      } else {
        console.error("Invalid product selection or quantity");
      }
    };

    const columns = (remove) => [
      {
        title: "Expenses",
        dataIndex: "expenseAccount",
        key: "expenseAccount",
        width: 300,
      },
      {
        title: "Details",
        dataIndex: "details",
        key: "details",
        width: 300,
      },
      { title: "Rate", dataIndex: "rate", key: "rate", width: 200 },
      {
        title: "Qty Required",
        dataIndex: "qtyRequired",
        key: "qtyRequired",
        width: 200,
      },
      { title: "Amount", dataIndex: "amount", key: "amount", width: 200 },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 110,
        render: (_, { key }) => (
          <ul className="inline-action">
            <li>
              <Link to={`#/`} onClick={() => remove(key)} className="red">
                <DeleteOutlined />
              </Link>
            </li>
          </ul>
        ),
      },
    ];
    const debouncedHandleFormChange = useCallback(
      debounce(() => {
        const fields = ExpenseForm.getFieldValue("users");
        const amounts = fields.map((item) => parseFloat(item.amount) || 0);
        const discount = fields.map((item) => parseFloat(item.discount) || 0);
        const tax = fields.map((item) => parseFloat(item.saleTax) || 0);
        const net = fields.map((item) => parseFloat(item.net) || 0);

        const totalAmount = amounts.reduce((sum, value) => sum + value, 0);
        const totalDiscount = discount.reduce((sum, value) => sum + value, 0);
        const totalSaleTax = tax.reduce((sum, value) => sum + value, 0);
        const total = net.reduce((sum, value) => sum + value, 0);

        setTotalDiscount(totalAmount);
        DetailsForm.setFieldsValue({
          subTotal: totalAmount,
          totalDiscount: totalDiscount,
          totalSaleTax: totalSaleTax,
          total: total,
        });
      }, 1000),
      []
    );

    return (
      <>
        <Form
          form={ExpenseForm}
          name="dynamic_form_nest_item"
          autoComplete="off"
          initialValues={{
            users: [{}],
          }}
        >
          <Form.List name="users">
            {(fields, { add, remove }) => (
              <>
                <Table
                  dataSource={fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => ({
                      key,
                      expenseAccount: (
                        <Form.Item
                          {...restField}
                          name={[name, "expenseAccount"]}
                          fieldKey={[fieldKey, "expenseAccount"]}
                        >
                          <Select
                            variant="borderless"
                            placeholder="Expense"
                            showSearch
                            filterOption={(input, option) =>
                              option.value
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(value) => {
                              handleSelectChange(value, index);
                            }}
                            style={{ width: 300 }}
                            options={ListOfRecords.map((fieldThreeitem) => ({
                              label: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                              value: `${fieldThreeitem.accountDescription}`,
                            }))}
                          ></Select>
                        </Form.Item>
                      ),
                      details: (
                        <Form.Item
                          {...restField}
                          name={[name, "details"]}
                          fieldKey={[fieldKey, "details"]}
                        >
                          <Input placeholder="Details" variant="borderless" />
                        </Form.Item>
                      ),
                      amount: (
                        <Form.Item
                          {...restField}
                          name={[name, "amount"]}
                          fieldKey={[fieldKey, "amount"]}
                          //onChange={(e) => handleAmountChange(e, index)}
                        >
                          <Input
                            placeholder="Amount"
                            type="Number"
                            variant="borderless"
                          />
                        </Form.Item>
                      ),
                      qtyRequired: (
                        <Form.Item
                          {...restField}
                          name={[name, "qtyRequired"]}
                          fieldKey={[fieldKey, "qtyRequired"]}
                          //onChange={(e) => handleDiscountChange(e, index)}
                        >
                          <Input
                            placeholder="Qty Required"
                            type="Number"
                            variant="borderless"
                          />
                        </Form.Item>
                      ),
                      rate: (
                        <Form.Item
                          {...restField}
                          name={[name, "rate"]}
                          fieldKey={[fieldKey, "rate"]}
                        >
                          <Input
                            placeholder="Rate"
                            type="Number"
                            variant="borderless"
                          />
                        </Form.Item>
                      ),
                      action: (
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      ),
                    })
                  )}
                  columns={columns(remove)}
                  pagination={false}
                  size="small"
                />

                <Form.Item>
                  <Button
                    style={{ marginTop: 10 }}
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </>
    );
  };

  const items = [
    {
      label: "Approve and New",
      key: "1",
    },
    {
      label: "Approve and Print",
      key: "2",
    },
    {
      label: "Approve and Email",
      key: "3",
    },
  ];

  return (
    <>
      <AddProductModal
        show={productOpen}
        handleOk={handleProductOk}
        handleCancel={handleProductCancel}
        loading={CustomerLoading}
        ProductID={ProductID}
      />
      <div id="sub-menu-wrap">
        <h5>Assembly</h5>
        <AssemblyMenu />
      </div>
      <div className="right-side-contents">
        <div className="page-content mb-5">
          <div className="page-header">
            <SubMenuToggle />
            <h3 className="page-title">
              <NavLink to="/jobs/manage">
                <ArrowLeftIcon />
              </NavLink>
              Add Job
            </h3>
          </div>

          {!loading ? (
            <>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Collapse
                  collapsible="header"
                  defaultActiveKey={["Details"]}
                  items={[
                    {
                      key: "Details",
                      label: "Details",
                      children: <Details />,
                    },
                  ]}
                />
                <Collapse
                  collapsible="header"
                  defaultActiveKey={["Finished Goods"]}
                  items={[
                    {
                      key: "Finished Goods",
                      label: "Finished Goods",
                      children: <FinishedGoods />,
                    },
                  ]}
                />

                <Collapse
                  collapsible="header"
                  defaultActiveKey={["Raw Material"]}
                  items={[
                    {
                      key: "Raw Material",
                      label: "Raw Material",
                      children: <RawMaterial />,
                    },
                  ]}
                />
                <Collapse
                  collapsible="header"
                  defaultActiveKey={["Non Stock"]}
                  items={[
                    {
                      key: "Non Stock",
                      label: "Non Stock",
                      children: <NonStock />,
                    },
                  ]}
                />
                <Collapse
                  collapsible="header"
                  defaultActiveKey={["Expense Details"]}
                  items={[
                    {
                      key: "Expense Details",
                      label: "Expense Details",
                      children: <ExpenseDetail />,
                    },
                  ]}
                />
                <Form
                  layout="vertical"
                  form={DetailsForm}
                  onFinish={handleSubmit}
                >
                  <Row gutter={[24, 0]}>
                    <Col xs={24} md={12}>
                      <Form.Item name="notes" label="Notes">
                        <Input.TextArea rows={5} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Space>
              <Form
                layout="vertical"
                className="my-5"
                form={DetailsForm}
                onFinish={handleSubmit}
              >
                <Row justify="end" className="text-end">
                  <Col xs={24} md={{ span: 4, offset: 20 }}>
                    <Dropdown.Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      icon={<DownOutlined />}
                      menu={{ items }}
                    >
                      Approve and New
                    </Dropdown.Button>
                  </Col>
                </Row>
              </Form>
            </>
          ) : (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AddJob;
