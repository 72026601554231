import React, { useState, useEffect } from "react";
import { Button, Form, Select, DatePicker, Table, Empty, Divider } from "antd";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import Config from "../../Config";
import ProductionMenu from "./ProductionMenu";
import ReportsMenu from "../Reports/ReportsMenu";
import SubMenuToggle from "../Common/SubMenuToggle";
import ProductPrint from "../Shared/ProductPrint";
import Logo from "../../assets/images/logo/dark-h.svg";
import ProductsDropdown from "../Common/ProductsDropdown";
import { PrinterOutlined, UploadOutlined } from "@ant-design/icons";
import ExcelJS from "exceljs";

const ProductSummary = () => {
  const navigate = useNavigate();
  const AccessKey = localStorage.getItem("AccessKey");
  const CompanyID = localStorage.getItem("CompanyID");
  const User = localStorage.getItem("Full_Name");

  const [ProductForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);
  const [period, setPeriod] = useState("all");
  const [date, setDate] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const defaultDate = dayjs().format("YYYY-MM-DD");
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [SubCategory, setSubCategory] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [newType, setNewType] = useState([]);
  const [listOfProducts, setListOfProducts] = useState([]);
  const [productLoading, setProductLoading] = useState(false);

  const category = ProductForm.getFieldValue("category");
  const type = ProductForm.getFieldValue("type");

  useEffect(() => {
    document.title = "Products Summary";
    GetCategoryDropdownData();
    GetTypeDropdownData();
    fetchProducts();
  }, [category, type]);

  const fetchSupplierTransactions = async (formData) => {
    setLoading(true);
    setError(null);

    const effectiveDate =
      formData.period === "custom" && formData.date
        ? dayjs(formData.date).format("YYYY-MM-DD")
        : defaultDate;

    try {
      const api_config = {
        method: "get",
        url: `${
          Config.base_url
        }Reports/GetProductSummaryReportBy/${CompanyID}?productName=${
          selectedProduct ? selectedProduct.name : ""
        }&period=${
          formData.period
        }&reportDate=${effectiveDate}&includeAllProducts=${
          formData.name === "all"
        }&category=${category || ""}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessKey}`,
        },
      };
      const response = await axios(api_config);
      setTransactions(response.data.products || []);
      setShowTable(true);
    } catch (err) {
      console.error("Error fetching product transactions:", err);
      // setError("Failed to load transactions.");
      setTransactions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (date) => {
    setDate(date ? dayjs(date).format("YYYY-MM-DD") : null);
  };

  const fetchProducts = async () => {
    setProductLoading(true);
    const api_config = {
      method: "get",
      url: `${
        Config.base_url
      }Product/GetBy/${CompanyID}?&pageNumber=1&pageSize=1000000&category=${
        category || ""
      }&type=${type || ""}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessKey}`,
      },
    };

    try {
      const response = await axios(api_config);
      if (response.data && response.data.status_code === 1) {
        //console.log(response);
        setProductLoading(false);
        setListOfProducts(response.data.listofProducts || []);
      } else {
        setProductLoading(false);

        setListOfProducts([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setProductLoading(false);

      setListOfProducts([]);
    }
  };
  const GetCategoryDropdownData = async () => {
    setCategoryLoading(true);
    try {
      const response = await axios.get(
        Config.base_url +
          `DropdownData/GetDropdownData/${CompanyID}?Type=ProductCategory`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setSubCategory(response.data.dropdownData);
        setCategoryLoading(false);
      }
    } catch (error) {
      console.error(error);
      setCategoryLoading(false);
    }
  };

  const GetTypeDropdownData = async () => {
    try {
      const response = await axios.get(
        Config.base_url +
          `DropdownData/GetDropdownData/${CompanyID}?Type=ProductType`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setNewType(response.data.dropdownData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      title: "Sr#",
      dataIndex: "",
      key: "SR",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Product Code",
      dataIndex: "productCode",
      key: "productCode",
      sorter: (a, b) => a.productCode - b.productCode,
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => record.name.split(" (")[0],
      sorter: (a, b) => String(a.name).localeCompare(String(b.name)),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sorter: (a, b) => String(a.category).localeCompare(String(b.category)),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => String(a.type).localeCompare(String(b.type)),
    },

    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (value) => (value ? value.toFixed(2) : "0.00"),
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (value) => (value ? value.toFixed(2) : "0.00"),
      sorter: (a, b) => a.rate - b.rate,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (value) => (value ? value.toFixed(2) : "0.00"),
      sorter: (a, b) => a.amount - b.amount,
    },
  ];

  const summary = () => {
    let totalAmount = 0;

    transactions.forEach(({ amount }) => {
      totalAmount += amount || 0;
    });

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={7}>Total</Table.Summary.Cell>
        <Table.Summary.Cell>{totalAmount.toFixed(2)}</Table.Summary.Cell>
        <Table.Summary.Cell>
          {/* Balance can be calculated if needed */}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  const handleSelectChange = (value) => {
    //console.log(value);
    const product = listOfProducts.find((product) => product.name === value);
    setSelectedProduct(product);
  };

  const handleExport = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Product Summary");

    const productName = selectedProduct
      ? `${selectedProduct.name} (${selectedProduct.code})`
      : "All Products";

    sheet.mergeCells("A1:G2"); // Merge cells for the title
    const titleCell = sheet.getCell("C3:G3");
    titleCell.value = `Product Summary: ${productName}`;
    titleCell.font = { bold: true, size: 18 };
    titleCell.alignment = { vertical: "middle", horizontal: "center" };

    // Set column headers and their widths
    sheet.columns = [
      { header: "Product Code", key: "productCode", width: 15 },
      { header: "Product Name", key: "name", width: 30 },
      { header: "Category", key: "category", width: 20 },
      { header: "Type", key: "type", width: 20 },
      { header: "Quantity", key: "quantity", width: 20 },
      { header: "Rate", key: "rate", width: 20 },
      { header: "Amount", key: "amount", width: 20 },
    ];

    const headerRow = sheet.addRow([
      "Product Code",
      "Product Name",
      "Category",
      "Type",
      "Quantity",
      "Rate",
      "Amount",
    ]);

    // Apply styling to the header row
    headerRow.font = { bold: true, color: { argb: "FFFFFFFF" } }; // White text color
    headerRow.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF324F94" }, // Background color #324F94 (blue)
    };

    let totalAmount = 0;
    let totalQuantity = 0;
    let totalRate = 0;

    transactions.forEach((product, index) => {
      totalAmount += product.amount;
      totalQuantity += product.quantity;
      totalRate += product.rate;
      sheet.addRow({
        sr: index + 1,
        productCode: product.productCode,
        name: product.name,
        category: product.category,
        type: product.type,
        quantity: product.quantity,
        rate: product.rate,
        amount: product.amount,
      });
    });

    const totalsRow = sheet.addRow({
      name: "Totals",
      quantity: totalQuantity.toFixed(2),
      rate: totalRate.toFixed(2),
      amount: totalAmount.toFixed(2),
    });

    // Apply styling to the totals row
    totalsRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: "right" };
      if (colNumber > 1) {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    });

    const now = new Date();
    const dateString = now
      .toLocaleString("sv-SE", { timeZoneName: "short" }) // Format: YYYY-MM-DD HH:mm:ss
      .replace(/[^0-9]/g, ""); // Remove special characters like : and space

    // Generate the Excel file and prompt the user to download it
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `ProductSummary_${dateString}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <div id="sub-menu-wrap">
        <h5>Reports</h5>
        <ReportsMenu />
      </div>

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <SubMenuToggle />
            <h3 className="page-title">
              <NavLink to="/products/manage">
                <ArrowLeftIcon />
              </NavLink>
              Products Summary
            </h3>
            {showTable && transactions.length > 0 && (
              <div className="header-actions">
                <Button
                  type="dashed"
                  onClick={handleExport}
                  icon={<UploadOutlined />}
                >
                  Export Report
                </Button>
                <ProductPrint
                  selectedSupplier={selectedProduct}
                  startDate={date}
                  endDate={date}
                  User={User}
                  title="Product Summary"
                />
              </div>
            )}
          </div>

          <div className="filters-wrap">
            <Form onFinish={fetchSupplierTransactions} form={ProductForm}>
              <Form.Item name="category">
                <Select
                  style={{
                    width: 150,
                  }}
                  showSearch // Enable search functionality
                  filterOption={
                    (input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase()) // Use option.label instead of option.children
                  }
                  placeholder="Category"
                  options={SubCategory.map((fieldThreeitem) => ({
                    label: fieldThreeitem.name,
                    value: fieldThreeitem.name,
                  }))}
                  onSelect={fetchProducts}
                />
              </Form.Item>
              <Form.Item name="type">
                <Select
                  style={{
                    width: 150,
                  }}
                  showSearch // Enable search functionality
                  filterOption={
                    (input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase()) // Use option.label instead of option.children
                  }
                  placeholder="Type"
                  options={newType.map((fieldThreeitem) => ({
                    label: fieldThreeitem.name,
                    value: fieldThreeitem.name,
                  }))}
                  onSelect={fetchProducts}
                />
              </Form.Item>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please select a product",
                  },
                ]}
              >
                <Select
                  style={{ width: "300px" }}
                  placeholder="Select Product"
                  onSelect={handleSelectChange}
                  showSearch // Enable search functionality
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Select.Option value="all">All Products</Select.Option>
                  {listOfProducts.map((account) => (
                    <Select.Option key={account.id} value={account.name}>
                      {account.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="period" initialValue="year">
                <Select
                  onChange={setPeriod}
                  style={{ width: "150px" }}
                  placeholder="Date Range"
                >
                  <Select.Option value="all">All Dates</Select.Option>
                  <Select.Option value="custom">Custom</Select.Option>
                  <Select.Option value="today">Today</Select.Option>
                  <Select.Option value="week">This Week</Select.Option>
                  <Select.Option value="month">This Month</Select.Option>
                  <Select.Option value="last60Days">Last 60 Days</Select.Option>
                  <Select.Option value="year">This Year</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="reportDate">
                <DatePicker
                  value={date ? dayjs(date, "YYYY-MM-DD") : null}
                  style={{ width: "100%" }}
                  onChange={handleDateChange}
                />
              </Form.Item>

              <Button type="primary" htmlType="submit" loading={loading}>
                Run Report
              </Button>
            </Form>
          </div>

          {showTable && transactions.length > 0 && (
            <>
              <div className="reports-main-div">
                {/* Main Content */}

                <div className="report-content">
                  {/* Left: Account Code and Opening Balance */}
                  <div className="report-left">
                    <h2>
                      {selectedProduct ? selectedProduct.name : "All Products"}
                    </h2>
                    {selectedProduct && (
                      <>
                        <h3>Product Code: {selectedProduct.code}</h3>
                      </>
                    )}
                  </div>
                  <img className="report-company-name" src={Logo} />
                  {/* Right: User Name and Printed Date */}
                  <div className="report-right">
                    <h2>Product Summary</h2>
                    <h3>Printed by: {User} </h3>
                    <h3>Printed on: {new Date().toLocaleString()}</h3>
                  </div>
                </div>
              </div>
              <Table
                columns={columns}
                dataSource={transactions}
                rowKey="id"
                summary={summary}
                pagination={false}
              />
            </>
          )}

          {showTable && transactions.length <= 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </div>
    </>
  );
};

export default ProductSummary;
